import { Model, useRepo } from "pinia-orm";

import { BaseModel } from "src/models/Base.js";

import { Contact } from "src/models/Contact.js";
import { Customer } from "src/models/Payment.js";
import { File } from "src/models/File.js";
import { JournaObject } from "src/models/Jobject.js";
import { Location } from "src/models/Location.js";
import { Message } from "src/models/Message.js";
import { User } from "src/models/User.js";

class NodeContact extends Model {
  static fields() {
    return {
      nodeId: this.uid(),
      contactId: this.uid(),
    };
  }
}
NodeContact.entity = "node_contact";
NodeContact.primaryKey = ["nodeId", "contactId"];

class NodeFile extends Model {
  static fields() {
    return {
      nodeId: this.uid(),
      fileId: this.uid(),
    };
  }
}
NodeFile.entity = "node_file";
NodeFile.primaryKey = ["nodeId", "fileId"];

export class NodeJobject extends Model {
  static fields() {
    return {
      nodeId: this.uid(),
      jobjectId: this.uid(),
    };
  }
}
NodeJobject.entity = "node_jobject";
NodeJobject.primaryKey = ["nodeId", "jobjectId"];

class NodeMessage extends Model {
  static fields() {
    return {
      nodeId: this.uid(),
      messageId: this.uid(),
    };
  }
}
NodeMessage.entity = "node_message";
NodeMessage.primaryKey = ["nodeId", "messageId"];

class NodeLocation extends Model {
  static fields() {
    return {
      nodeId: this.uid(),
      locationId: this.uid(),
    };
  }
}
NodeLocation.entity = "node_location";
NodeLocation.primaryKey = ["nodeId", "locationId"];

class NodeCustomer extends Model {
  static fields() {
    return {
      nodeId: this.uid(),
      customerId: this.uid(),
    };
  }
}
NodeCustomer.entity = "node_customer";
NodeCustomer.primaryKey = ["nodeId", "customerId"];

export class Node extends BaseModel {
  static fields() {
    return {
      $hash: this.attr({}),
      id: this.uid(),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      completed_at: this.attr(null),
      createdById: this.attr(null),
      updatedById: this.attr(null),
      completedById: this.attr(null),
      created_by: this.belongsTo(User, "createdById"),
      updated_by: this.belongsTo(User, "updatedById"),
      completed_by: this.belongsTo(User, "completedById"),
      parent_id: this.attr(null),
      parent: this.belongsTo(Node, "parent_id"),
      kind: this.string(""),
      variety: this.string(""),
      is_archived: this.boolean(false),
      is_deleted: this.boolean(false),
      is_draft: this.boolean(true),
      is_auto_completed: this.boolean(false),
      path: this.string(""),
      path_text: this.attr([]),
      name: this.string(""),
      pid: this.string(""),
      status: this.string(""),
      priority: this.string(""),
      due_date: this.attr(null),
      start_date: this.attr(null),
      end_date: this.attr(null),
      send_date: this.attr(null),
      send_frequency: this.number(0),
      time_estimate: this.number(0),
      frequency: this.number(0),
      is_recurring: this.boolean(false),
      is_routable: this.boolean(false),
      step: this.number(0),
      stop: this.number(0),
      data: this.attr({}),
      is_leaf: this.boolean(true),
      permissions: this.attr([]),

      // relationships
      contact_ids: this.attr([]),
      contacts: this.hasManyBy(Contact, "contact_ids"),
      customer_ids: this.attr([]),
      customers: this.hasManyBy(Customer, "customer_ids"),
      file_ids: this.attr([]),
      files: this.hasManyBy(File, "file_ids"),
      jobject_ids: this.attr([]),
      jobjects: this.hasManyBy(JournaObject, "jobject_ids"),
      location_ids: this.attr([]),
      locations: this.hasManyBy(Location, "location_ids"),
      message_ids: this.attr([]),
      messages: this.hasManyBy(Message, "message_ids"),
      node_path_text: this.attr(null),
    };
  }
  /*
  static saving(model) {
    fieldsMap.forEach((f) => {
      f.repo.save(
        model[f.field].map((id) => {
          return { nodeId: model.id, [f.relationKey]: id };
        })
      );
    });
  }
  static deleting(model) {
    fieldsMap.forEach((f) => {
      f.repo
        .where((pivot) => model[f.field].includes(pivot[f.relationKey]))
        .where("nodeId", model.id)
        .delete();
    });
  }
  */
  get assigned() {
    return this.permissions.filter(
      (subject) => !subject.is_group && subject.permissions.includes("assigned")
    );
  }
  get viewers() {
    return this.permissions.filter(
      (subject) =>
        !subject.is_group && subject.permissions.includes("view_node")
    );
  }
  get changers() {
    return this.permissions.filter(
      (subject) =>
        !subject.is_group && subject.permissions.includes("change_node")
    );
  }
  get pivotMap() {
    return {
      contacts: useRepo(NodeContact),
      customers: useRepo(NodeCustomer),
      files: useRepo(NodeFile),
      jobjects: useRepo(NodeJobject),
      locations: useRepo(NodeLocation),
      messages: useRepo(NodeMessage),
    };
  }
}
Node.entity = "nodes";

import { Model } from "pinia-orm";
import { Timeclock } from "src/models/Timeclock.js";
import { User } from "src/models/User.js";

export class StripeAccount extends Model {
  static fields() {
    return {
      id: this.uid(),
      userId: this.attr(null),
      user: this.belongsTo(User, "userId"),
      account_id: this.string(""),
    };
  }
}
StripeAccount.entity = "stripe_accounts";

export class Transaction extends Model {
  static fields() {
    return {
      $hash: this.attr({}),
      id: this.uid(),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      createdById: this.attr(null),
      updatedById: this.attr(null),
      created_by: this.belongsTo(User, "createdById"),
      updated_by: this.belongsTo(User, "updatedById"),
      kind: this.string(""),
      status: this.string(""),
      amount: this.number(0),
      transfer_group: this.string(""),
      data: this.attr({}),
      stripeAccountId: this.attr(null),
      stripe_account: this.belongsTo(StripeAccount, "stripeAccountId"),
      timeclocks: this.belongsToMany(
        Timeclock,
        TransactionTimeclock,
        "transactionId",
        "timeclockId"
      ),
      node: this.attr(null),
      payment_series: this.attr(null),
      tags: this.attr(null),
    };
  }
}
Transaction.entity = "transactions";

export class TransactionTimeclock extends Model {
  static fields() {
    return {
      transactionId: this.attr(null),
      timeclockId: this.attr(null),
    };
  }
}
TransactionTimeclock.entity = "transaction_timeclock";
TransactionTimeclock.primaryKey = ["transactionId", "timeclockId"];

export class PaymentSeries extends Model {
  static fields() {
    return {
      id: this.uid(),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      createdById: this.attr(null),
      updatedById: this.attr(null),
      created_by: this.belongsTo(User, "createdById"),
      updated_by: this.belongsTo(User, "updatedById"),
      kind: this.string(""),
      status: this.string(""),
      name: this.string(""),
      description: this.string(""),
      billing_start: this.date(""),
      billing_end: this.attr(null),
      next_billing_date: this.attr(null),
      cadence: this.string(""),
      is_installment: this.boolean(false),
      is_subscription: this.boolean(false),
      is_autopay: this.boolean(false),
      use_credit: this.boolean(false),
      credit_balance: this.number(0),
      credit_amount: this.number(0),
      instalment_financed: this.number(0),
      installment_balance: this.number(0),
      installment_payment: this.number(0),
      installment_apr: this.number(0),
      subscription_payment: this.number(0),
      payment: this.number(0),
      tax_rate: this.number(0),
      data: this.attr({}),
      points: this.number(0),
    };
  }
}
PaymentSeries.entity = "payment_series";

export class Invoice extends Model {
  static fields() {
    return {
      id: this.uid(),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      createdById: this.attr(null),
      updatedById: this.attr(null),
      created_by: this.belongsTo(User, "createdById"),
      updated_by: this.belongsTo(User, "updatedById"),
      kind: this.string(""),
      status: this.string(""),
      data: this.attr({}),
    };
  }
}
Invoice.entity = "invoices";

import { defineStore } from "pinia";

import { api } from "src/boot/axios.js";

import { useWebsocketStore } from "src/stores/websocket.js";

import { Cookies, LocalStorage } from "quasar";
import { taxWithholdingRequests } from "src/utils/application";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    csrftoken: null,
    user: null,
    token: null,
    isLoggedIn: false,
  }),
  getters: {
    isAdmin(state) {
      if (!state.user) return false;
      return state.user.groups.map((g) => g.name).includes("Admin");
    },
    isContractor(state) {
      if (!state.user) return false;
      return state.user.groups.map((g) => g.name).includes("Contractor");
    },
    isCrewMember(state) {
      if (!state.user) return false;
      return state.user.groups.map((g) => g.name).includes("Crew Member");
    },
    isCrewLeader(state) {
      if (!state.user) return false;
      return state.user.groups.map((g) => g.name).includes("Crew Leader");
    },
    isCustomer(state) {
      if (!state.user) return false;
      return (
        state.user.groups.map((g) => g.name).includes("Customer") ||
        state.user.groups.map((g) => g.name).includes("New Customer")
      );
    },
    isManager(state) {
      if (!state.user) return false;
      return state.user.groups.map((g) => g.name).includes("Manager");
    },
  },

  actions: {
    loginUser(token, user) {
      this.csrftoken = Cookies.get("csrftoken");
      this.isLoggedIn = true;
      this.token = token;
      this.user = user;

      LocalStorage.set("isLoggedIn", true);
      LocalStorage.set("authToken", token);
      LocalStorage.set("authUser", user);
    },
    logoutUser() {
      const websocket = useWebsocketStore();
      websocket.close();

      this.isLoggedIn = false;
      this.token = null;
      this.user = null;

      LocalStorage.remove("isLoggedIn");
      LocalStorage.remove("authUser");
      LocalStorage.remove("authToken");
    },
    async register(email, kind, login, data) {
      try {
        const response = await api.post("public/new_user", {
          kind: kind,
          email: email,
          login: login ? true : false,
          ...data,
        });

        this.loginUser(response.data.token, response.data.user);
        return true;
      } catch (error) {
        return false;
      }
    },
    async login(username, password, quasar, router, path) {
      try {
        const response = await api.post("auth/login", {
          username: username.toLowerCase(),
          password: password,
        });

        this.loginUser(response.data.token, response.data.user);
      } catch (error) {
        if (quasar && error.response.status == 400) {
          if (error.response.data.hasOwnProperty("non_field_errors")) {
            quasar.notify(error.response.data.non_field_errors[0]);
          } else {
            quasar.notify("Invalid email or password.");
          }
        }

        return false;
      }

      if (path) {
        router.replace(path);
        return true;
      } else {
        router.go();
        return true;
      }
    },
    async logout(router) {
      await api.post("auth/logout");
      this.logoutUser();
      router.replace("/");
    },
    async passwordReset(email, quasar) {
      try {
        await api.post("auth/password/reset", { email: email });
        quasar.notify({
          message:
            "An email has been sent with instructions to reset your password.",
          color: "dark",
          position: "top",
          timeout: 0,
          actions: [
            {
              icon: "close",
              color: "white",
            },
          ],
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
});

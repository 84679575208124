/* eslint-disable */
/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.config.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/



import {Notify,Dialog,BottomSheet,LocalStorage,Loading,Cookies} from 'quasar'



export default { config: {"loading":{"spinnerColor":"primary","spinnerSize":140,"delay":0,"backgroundColor":"white","customClass":"bg-white text-black text-h5","messageColor":"primary"}},plugins: {Notify,Dialog,BottomSheet,LocalStorage,Loading,Cookies} }


const routes = {
  landing: [
    {
      name: "landing",
      path: "/",
      component: () => import("src/layouts/LandingLayout.vue"),
      children: [
        {
          name: "index",
          path: "",
          component: () => import("src/pages/landing/IndexPage.vue"),
        },
        {
          name: "careers",
          path: "careers",
          component: () => import("src/pages/landing/CareersPage.vue"),
        },
        {
          name: "contractors",
          path: "pros",
          component: () =>
            import("src/pages/landing/contractor/ContractorPage.vue"),
        },
        {
          name: "privacy",
          path: "privacy",
          component: () => import("src/pages/landing/PrivacyPolicy.vue"),
        },
        {
          name: "terms",
          path: "terms",
          component: () => import("src/pages/landing/TermsAndConditions.vue"),
        },
      ],
    },
    {
      name: "basic",
      path: "/",
      component: () => import("src/layouts/BasicLandingLayout.vue"),
      children: [
        {
          name: "reset-password",
          path: "/password-reset/confirm/:uid/:token",
          component: () => import("src/pages/PasswordResetPage.vue"),
        },
        {
          name: "catchAll",
          path: "/:catchAll(.*)*",
          component: () => import("pages/ErrorNotFound.vue"),
        },
      ],
    },
  ],
  customer: [
    {
      name: "customer",
      path: "/",
      component: () => import("src/layouts/CustomerLayout.vue"),
      children: [
        {
          name: "index",
          path: "",
          component: () => import("src/pages/customer/CustomerIndex.vue"),
        },
        {
          name: "application",
          path: "application",
          component: () => import("src/pages/customer/CustomerApplication.vue"),
        },
        /*
        {
          name: "billing",
          path: "billing",
          component: () => import("src/components/portal/Billing.vue"),
        },
        {
          name: "accounts",
          path: "accounts",
          component: () => import("src/components/portal/Accounts.vue"),
        },
        {
          name: "properties",
          path: "properties",
          component: () => import("src/components/portal/Properties.vue"),
        },
        {
          name: "contacts",
          path: "contacts",
          component: () => import("src/components/portal/Contacts.vue"),
        },
        {
          name: "subscriptions",
          path: "subscriptions",
          component: () => import("src/components/portal/Subscriptions.vue"),
        },
        {
          name: "quotes",
          path: "quotes",
          component: () => import("src/components/portal/Quotes.vue"),
        },
        {
          name: "quote",
          path: "quotes/:id",
          component: () => import("src/components/portal/Quote2.vue"),
        },
        {
          name: "invoices",
          path: "invoices",
          component: () => import("src/components/portal/Invoices.vue"),
        },
        {
          name: "records",
          path: "records",
          component: () => import("src/components/portal/Records.vue"),
        },
        {
          name: "record",
          path: "records/:id",
          component: () => import("src/components/portal/ServiceRecord.vue"),
        },
        {
          name: "calendar",
          path: "calendar",
          component: () => import("src/components/portal/Calendar.vue"),
        },
        {
          name: "messages",
          path: "messages",
          component: () => import("src/components/portal/Messages.vue"),
        },
        {
          name: "rewards",
          path: "rewards",
          component: () => import("src/components/portal/Rewards.vue"),
        },
        {
          name: "transactions",
          path: "transactions",
          component: () => import("src/components/portal/Transactions.vue"),
        },
        {
          name: "user",
          path: "user",
          component: () => import("src/components/portal/User.vue"),
        },
        {
          name: "security",
          path: "security",
          component: () => import("src/components/portal/Security.vue"),
        },
        */
      ],
    },
    {
      name: "catchAll",
      path: "/:catchAll(.*)*",
      component: () => import("pages/ErrorNotFound.vue"),
    },
  ],

  manager: [
    {
      name: "manager",
      path: "/",
      component: () => import("src/layouts/ManagerLayoutNew.vue"),
      children: [
        {
          path: "/search",
          name: "search",
          component: () => import("src/pages/search/SearchPage.vue"),
        },
        {
          path: "contacts/:id",
          component: () => import("src/pages/contacts/ContactPage.vue"),
        },
        {
          path: "contacts",
          component: () => import("src/pages/contacts/ContactsPage.vue"),
        },
        {
          path: "messages/:id",
          component: () => import("src/pages/messages/MessagePage.vue"),
        },
        {
          path: "messages",
          component: () => import("src/pages/messages/MessagesPage.vue"),
        },
        {
          path: "locations/:id",
          component: () => import("src/pages/locations/LocationPage.vue"),
        },
        {
          path: "locations",
          component: () => import("src/pages/locations/LocationsPage.vue"),
        },
        {
          path: "payments/:id",
          component: () => import("src/pages/payments/PaymentPage.vue"),
        },
        {
          path: "payments",
          component: () => import("src/pages/payments/PaymentsPage.vue"),
        },
        /*{
          path: "transactions/:id",
          component: () => import("src/pages/transactions/TransactionPage.vue"),
        },*/
        {
          path: "transactions",
          component: () =>
            import("src/pages/transactions/TransactionsPage.vue"),
        },
        {
          path: "routables/:id",
          component: () => import("src/pages/routables/RoutablePage.vue"),
        },
        {
          path: "routables",
          component: () => import("src/pages/routables/RoutablesPage.vue"),
        },
        {
          path: "jobjects/:id",
          component: () => import("src/pages/jobjects/JobjectPage.vue"),
        },
        {
          path: "jobjects",
          component: () => import("src/pages/jobjects/JobjectsPage.vue"),
        },
        {
          path: "files/:id",
          component: () => import("src/pages/files/FilePage.vue"),
        },
        {
          path: "files",
          component: () => import("src/pages/files/FilesPage.vue"),
        },
        {
          path: "tags/:id",
          component: () => import("src/pages/tags/TagPage.vue"),
        },
        {
          path: "tags",
          component: () => import("src/pages/tags/TagsPage.vue"),
        },
        {
          path: "timeclocks/:id",
          component: () => import("src/pages/timeclocks/TimeclockPage.vue"),
        },
        {
          path: "timeclocks",
          component: () => import("src/pages/timeclocks/TimeclocksPage.vue"),
        },
        {
          path: "users/:id",
          component: () => import("src/pages/users/UserPage.vue"),
        },
        {
          path: "users",
          component: () => import("src/pages/users/UsersPage.vue"),
        },
        {
          path: "groups/:id",
          component: () => import("src/pages/groups/GroupPage.vue"),
        },
        {
          path: "groups",
          component: () => import("src/pages/groups/GroupsPage.vue"),
        },
        {
          path: "/nodes",
          name: "nodes",
          component: () => import("src/pages/nodes/NodesPage.vue"),
        },
        {
          path: "/nodes/:id",
          name: "node",
          component: () => import("src/pages/node/NodePage.vue"),
        },
        {
          path: "/nodes/:id/:subpage",
          name: "node-subpage",
          component: () => import("src/pages/node/NodePage.vue"),
        },
        {
          path: "/property-search",
          name: "property-search",
          component: () => import("src/components/PropertySelector.vue"),
        },
        {
          path: "/change-password",
          name: "change-password",
          component: () => import("src/pages/ChangePassword.vue"),
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("src/pages/crew/CrewProfile.vue"),
        },
        {
          name: "mytimeclock",
          path: "my-timeclock",
          component: () => import("src/pages/timeclock/MyTimeClock.vue"),
        },
        {
          name: "manage-tags",
          path: "manage-tags",
          component: () => import("src/pages/ManageTags.vue"),
        },
        /*
        {
          name: "weather",
          path: "weather",
          component: () => import("src/pages/weather/WeatherIndex.vue"),
        },
        */
        {
          name: "requests",
          path: "requests",
          component: () => import("src/pages/requests/RequestsView.vue"),
        },
        {
          name: "tag",
          path: "tag",
          component: () => import("src/pages/manager/Tags.vue"),
        },
        {
          name: "image-test",
          path: "image-test",
          component: () => import("src/pages/manager/ImageTest.vue"),
        },
        {
          name: "processes",
          path: "processes",
          component: () => import("src/pages/manager/ProcessesPage.vue"),
        },
        {
          name: "pro-applications",
          path: "pro-applications",
          component: () => import("src/pages/manager/ProApplications.vue"),
        },
        {
          name: "pro-application",
          path: "pro-applications/:id",
          component: () =>
            import("src/pages/manager/application/ProApplication.vue"),
        },
        {
          name: "customer-onboarding",
          path: "customer-onboarding",
          component: () => import("src/pages/manager/CustomerOnboarding.vue"),
        },
        {
          name: "pros",
          path: "pros",
          component: () => import("src/pages/manager/Pros.vue"),
        },
        /*
        {
          name: "communications",
          path: "communications",
          component: () => import("src/pages/Communications.vue"),
        },
        */
        /*
        {
          name: "payment-test",
          path: "payment-test",
          component: () => import("src/pages/PaymentTest.vue"),
        },
        {
          name: "payment-test-success",
          path: "payment-test/:success",
          component: () => import("src/pages/PaymentTest.vue"),
        },
        {
          name: "node-test",
          path: "node-test",
          component: () => import("src/pages/NodeTest.vue"),
        },
        */
        {
          path: "accounts",
          component: () => import("src/pages/manager/CustomerAccounts.vue"),
        },
        {
          path: "accounts/:id",
          component: () => import("src/views/AccountPage.vue"),
        },
        /*
        {
          path: "messages",
          component: () => import("src/views/Messages2.vue"),
        },
        {
          path: "messages/:id",
          component: () => import("src/views/Messages.vue"),
        },
        */
        {
          path: "properties",
          component: () => import("src/pages/manager/CustomerProperties.vue"),
        },
        /*{
          path: "properties",
          component: () => import("src/views/Properties.vue"),
        },
        {
          path: "properties/:id",
          component: () => import("src/views/PropertyPage.vue"),
        },
        */
        {
          path: "scheduler",
          component: () => import("src/pages/scheduler/SchedulerIndex.vue"),
        },
        /*
        {
          path: "/tasks",
          component: () => import("src/pages/TasksTable.vue"),
        },
        {
          name: "task",
          path: "/tasks/:id",
          component: () => import("src/pages/TaskPage.vue"),
        },
        {
          path: "service-records",
          component: () => import("pages/ServiceRecords.vue"),
        },
        */
        {
          name: "timeclock",
          path: "/timeclock",
          component: () => import("src/pages/timeclock/TimeSheetIndex.vue"),
        },
        {
          name: "mytimeclock",
          path: "/my-timeclock",
          component: () => import("src/pages/timeclock/MyTimeClock.vue"),
        },
        {
          name: "home",
          path: "",
          component: () => import("src/pages/manager/Index.vue"),
        },
      ],
    },
    {
      name: "documentation",
      path: "/docs",
      component: () => import("src/layouts/DocumentationLayout.vue"),
      children: [
        {
          path: "terms/account",
          name: "account-term",
          component: () => import("src/pages/docs/terms/AccountTerm.vue"),
        },
        {
          path: "terms/property",
          name: "property-term",
          component: () => import("src/pages/docs/terms/PropertyTerm.vue"),
        },
        {
          path: "terms/contract",
          name: "contract-term",
          component: () => import("src/pages/docs/terms/ContractTerm.vue"),
        },
        {
          path: "terms/crm",
          name: "crm-term",
          component: () => import("src/pages/docs/terms/CrmTerm.vue"),
        },
        {
          path: "terms/customer",
          name: "customer-term",
          component: () => import("src/pages/docs/terms/CustomerTerm.vue"),
        },
        {
          path: "terms/professional",
          name: "professional-term",
          component: () => import("src/pages/docs/terms/ProfessionalTerm.vue"),
        },
        {
          path: "terms/quote",
          name: "quote-term",
          component: () => import("src/pages/docs/terms/QuoteTerm.vue"),
        },
        {
          path: "terms/request",
          name: "request-term",
          component: () => import("src/pages/docs/terms/RequestTerm.vue"),
        },
        {
          path: "terms/service-record",
          name: "service-record-term",
          component: () => import("src/pages/docs/terms/ServiceRecordTerm.vue"),
        },
        {
          path: "terms/service",
          name: "service-term",
          component: () => import("src/pages/docs/terms/ServiceTerm.vue"),
        },
        {
          path: "terms/service-visit",
          name: "service-visit-term",
          component: () => import("src/pages/docs/terms/ServiceVisitTerm.vue"),
        },
        {
          path: "terms/tag",
          name: "tag-term",
          component: () => import("src/pages/docs/terms/TagTerm.vue"),
        },
        {
          path: "terms/timeclock",
          name: "timeclock-term",
          component: () => import("src/pages/docs/terms/TimeclockTerm.vue"),
        },
        {
          path: "models",
          name: "models",
          component: () => import("src/pages/docs/models/ModelsPage.vue"),
        },
        {
          path: "models/user",
          name: "user",
          component: () => import("src/pages/docs/models/UserModel.vue"),
        },
        {
          path: "models/group",
          name: "group",
          component: () => import("src/pages/docs/models/GroupModel.vue"),
        },
        {
          path: "models/file",
          name: "file",
          component: () => import("src/pages/docs/models/FileModel.vue"),
        },
        {
          path: "models/contact",
          name: "contact",
          component: () => import("src/pages/docs/models/ContactModel.vue"),
        },
        {
          path: "models/object",
          name: "object",
          component: () => import("src/pages/docs/models/JobjectModel.vue"),
        },
        {
          path: "models/location",
          name: "location",
          component: () => import("src/pages/docs/models/LocationModel.vue"),
        },
        {
          path: "models/payment",
          name: "payment",
          component: () => import("src/pages/docs/models/PaymentModel.vue"),
        },
        {
          path: "models/routable",
          name: "routable",
          component: () => import("src/pages/docs/models/RoutableModel.vue"),
        },
        {
          path: "models/tag",
          name: "tag",
          component: () => import("src/pages/docs/models/TagModel.vue"),
        },
        {
          path: "models/timeclock",
          name: "timeclock",
          component: () => import("src/pages/docs/models/TimeclockModel.vue"),
        },
        {
          path: "models/transaction",
          name: "transaction",
          component: () => import("src/pages/docs/models/TransactionModel.vue"),
        },
        {
          path: "models/node",
          name: "node",
          component: () => import("src/pages/docs/models/NodeModel.vue"),
        },
        {
          path: "models/message",
          name: "message",
          component: () => import("src/pages/docs/models/MessageModel.vue"),
        },
        {
          path: "workflows",
          name: "workflows",
          component: () => import("src/pages/docs/workflows/WorkflowsPage.vue"),
        },
        {
          path: "accounts-workflow",
          name: "accounts-workflow",
          component: () =>
            import("src/pages/docs/workflows/AccountsWorkflow.vue"),
        },
        {
          path: "new-customer-workflow",
          name: "new-customer-workflow",
          component: () =>
            import("src/pages/docs/workflows/NewCustomerWorkflow.vue"),
        },
        {
          path: "new-customer-workflow-application",
          name: "new-customer-workflow-application",
          component: () =>
            import(
              "src/pages/docs/workflows/NewCustomerWorkflowApplication.vue"
            ),
        },
        {
          path: "new-customer-workflow-initial-review",
          name: "new-customer-workflow-initial-review",
          component: () =>
            import(
              "src/pages/docs/workflows/NewCustomerWorkflowInitialReview.vue"
            ),
        },
        {
          path: "new-customer-workflow-inspection",
          name: "new-customer-workflow-inspection",
          component: () =>
            import(
              "src/pages/docs/workflows/NewCustomerWorkflowInspection.vue"
            ),
        },
        {
          path: "new-customer-workflow-quote",
          name: "new-customer-workflow-quote",
          component: () =>
            import("src/pages/docs/workflows/NewCustomerWorkflowQuote.vue"),
        },
        {
          path: "new-customer-workflow-contract",
          name: "new-customer-workflow-contract",
          component: () =>
            import("src/pages/docs/workflows/NewCustomerWorkflowContract.vue"),
        },
        {
          path: "new-professional-workflow",
          name: "new-professional-workflow",
          component: () =>
            import("src/pages/docs/workflows/NewProfessionalWorkflow.vue"),
        },
        {
          path: "new-professional-workflow-application",
          name: "new-professional-workflow-application",
          component: () =>
            import(
              "src/pages/docs/workflows/NewProfessionalWorkflowApplication.vue"
            ),
        },
        {
          path: "new-professional-workflow-initial-review",
          name: "new-professional-workflow-initial-review",
          component: () =>
            import(
              "src/pages/docs/workflows/NewProfessionalWorkflowInitialReview.vue"
            ),
        },
        {
          path: "new-professional-workflow-additional-steps",
          name: "new-professional-workflow-additional-steps",
          component: () =>
            import(
              "src/pages/docs/workflows/NewProfessionalWorkflowAdditionalSteps.vue"
            ),
        },
        {
          path: "service-visit-workflow",
          name: "servservice-visiticing-workflow",
          component: () =>
            import("src/pages/docs/workflows/ServiceVisitWorkflow.vue"),
        },
        {
          path: "customer-payment-workflow",
          name: "customer-payment-workflow",
          component: () =>
            import("src/pages/docs/workflows/CustomerPaymentWorkflow.vue"),
        },
        {
          path: "professional-payment-workflow",
          name: "professional-payment-workflow",
          component: () =>
            import("src/pages/docs/workflows/ProfessionalPaymentWorkflow.vue"),
        },
        {
          path: "time-tracking",
          name: "time-tracking",
          component: () => import("src/pages/docs/workflows/TimeTracking.vue"),
        },
        {
          path: "requests",
          name: "requests",
          component: () => import("src/pages/docs/workflows/RequestsPage.vue"),
        },
        {
          path: "processes",
          name: "processes",
          component: () => import("src/pages/docs/processes/ProcessesPage.vue"),
        },
        {
          path: "tree",
          name: "tree",
          component: () => import("src/pages/docs/TreePage.vue"),
        },
      ],
    },
    {
      name: "catchAll",
      path: "/:catchAll(.*)*",
      component: () => import("pages/ErrorNotFound.vue"),
    },
  ],
  crew: [
    {
      name: "crew",
      path: "/",
      component: () => import("src/layouts/CrewLayout.vue"),
      children: [
        /*{
          name: "index",
          path: "",
          component: () => import("src/pages/crew/Index.vue"),
        },*/
        {
          name: "mytimeclock1",
          path: "",
          component: () => import("src/pages/timeclock/MyTimeClock.vue"),
        },
        {
          name: "mytimeclock",
          path: "/my-timeclock",
          component: () => import("src/pages/timeclock/MyTimeClock.vue"),
        },
        {
          name: "timeclocks",
          path: "/timeclocks",
          component: () => import("src/pages/timeclock/TimeclockPage.vue"),
        },
        /*
        {
          name: "confirm",
          path: "confirm",
          component: () => import("src/pages/ConfirmTimeclock.vue"),
        },
        */
        {
          name: "requests",
          path: "requests",
          component: () => import("src/pages/RequestsList.vue"),
        },
        {
          name: "request",
          path: "requests/:id",
          component: () => import("src/pages/RequestActionPage.vue"),
        },
        {
          path: "/change-password",
          name: "change-password",
          component: () => import("src/pages/ChangePassword.vue"),
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("src/pages/crew/CrewProfile.vue"),
        },
        /*
        {
          path: "/tasks",
          name: "tasks",
          component: () => import("src/pages/TasksTable.vue"),
        },
        {
          path: "/tasks/:id",
          name: "task",
          component: () => import("src/pages/crew/Task.vue"),
        },
        */
      ],
    },
    {
      name: "catchAll",
      path: "/:catchAll(.*)*",
      component: () => import("pages/ErrorNotFound.vue"),
    },
  ],
  contractor: [
    {
      name: "contractor",
      path: "/",
      component: () => import("src/layouts/NewProLayout.vue"),
      children: [
        {
          name: "requests",
          path: "requests",
          component: () => import("src/pages/RequestsList.vue"),
        },
        {
          name: "request",
          path: "requests/:id",
          component: () => import("src/pages/RequestActionPage.vue"),
        },
        {
          name: "index",
          path: "",
          component: () => import("src/pages/contractor/Index.vue"),
        },
        {
          name: "application",
          path: "application",
          component: () => import("src/pages/pro/ProApplication.vue"),
        },
        {
          path: "profile",
          name: "profile",
          component: () => import("src/pages/crew/CrewProfile.vue"),
        },
        /*{
          name: "profile",
          path: "profile",
          component: () => import("src/components/portal/CrewProfile.vue"),
        },*/
        {
          name: "change-password",
          path: "change-password",
          component: () => import("src/pages/ChangePassword.vue"),
        },
        {
          name: "privacy",
          path: "privacy",
          component: () => import("src/pages/landing/PrivacyPolicy.vue"),
        },
        {
          name: "terms",
          path: "terms",
          component: () => import("src/pages/landing/TermsAndConditions.vue"),
        },
      ],
    },
    {
      name: "catchAll",
      path: "/:catchAll(.*)*",
      component: () => import("pages/ErrorNotFound.vue"),
    },
  ],
};

export default routes;

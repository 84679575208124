import { Model, useRepo } from "pinia-orm";

import { BaseModel } from "src/models/Base.js";

import { Location } from "src/models/Location.js";
import { Node } from "src/models/Node.js";
import { Tag } from "src/models/Tag.js";
import { User } from "src/models/User.js";

export class Contact extends Model {
  static fields() {
    return {
      $hash: this.attr({}),
      id: this.uid(),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      createdById: this.attr(null),
      updatedById: this.attr(null),
      created_by: this.belongsTo(User, "createdById"),
      updated_by: this.belongsTo(User, "updatedById"),
      kind: this.string(""),
      is_global: this.boolean(true),
      business_name: this.string(""),
      attention: this.string(""),
      first_name: this.string(""),
      middle_name: this.string(""),
      last_name: this.string(""),
      suffix: this.string(""),
      enable: this.boolean(true),
      enable_marketing: this.boolean(true),
      data: this.attr({}),
      tags: this.belongsToMany(Tag, TagContact, "contactId", "tagId"),
      userId: this.attr(null),
      user: this.belongsTo(User, "userId"),
      locations: this.belongsToMany(
        Location,
        LocationContact,
        "contactId",
        "locationId"
      ),
      emails: this.belongsToMany(
        ContactEmail,
        ContactEmailContact,
        "contactId",
        "contactEmailId"
      ),
      phones: this.belongsToMany(
        ContactPhone,
        ContactPhoneContact,
        "contactId",
        "contactPhoneId"
      ),
    };
  }
}
Contact.entity = "contacts";

class TagContact extends Model {
  static fields() {
    return {
      tagId: this.attr(null),
      contactId: this.attr(null),
    };
  }
}
TagContact.entity = "tag_contact";
TagContact.primaryKey = ["tagId", "contactId"];

export class LocationContact extends Model {
  static fields() {
    return {
      locationId: this.uid(),
      contactId: this.uid(),
    };
  }
}
LocationContact.entity = "location_contact";
LocationContact.primaryKey = ["locationId", "contactId"];

class ContactEmailContact extends Model {
  static fields() {
    return {
      contactEmailId: this.uid(),
      contactId: this.uid(),
    };
  }
}
ContactEmailContact.entity = "contact_email_contact";
ContactEmailContact.primaryKey = ["contactEmailId", "contactId"];

class ContactPhoneContact extends Model {
  static fields() {
    return {
      contactPhoneId: this.uid(),
      contactId: this.uid(),
    };
  }
}
ContactPhoneContact.entity = "contact_phone_contact";
ContactPhoneContact.primaryKey = ["contactPhoneId", "contactId"];

export class ContactEmail extends BaseModel {
  static fields() {
    return {
      $hash: this.attr(null),
      id: this.uid(),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      createdById: this.attr(null),
      updatedById: this.attr(null),
      created_by: this.belongsTo(User, "createdById"),
      updated_by: this.belongsTo(User, "updatedById"),
      kind: this.string(""),
      email_address: this.string(""),
      enable: this.boolean(true),
      enable_marketing: this.boolean(true),
      is_verified: this.boolean(false),
      contact_ids: this.attr([]),
      contacts: this.hasManyBy(Contact, "contact_ids"),
    };
  }
  /*
  static saving(model) {
    useRepo(ContactEmailContact).save(
      model.contact_ids.map((id) => {
        return { contactEmailId: model.id, contactId: id };
      })
    );
  }
  static deleting(model) {
    useRepo(ContactEmailContact)
      .where((pivot) => model.contact_ids.includes(pivot.contactId))
      .where("contactEmailId", model.id)
      .delete();
  }
  */
  get pivotMap() {
    return {
      contacts: useRepo(ContactEmailContact),
    };
  }
}
ContactEmail.entity = "contact_emails";

export class ContactPhone extends BaseModel {
  static fields() {
    return {
      $hash: this.attr(null),
      id: this.uid(),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      createdById: this.attr(null),
      updatedById: this.attr(null),
      created_by: this.belongsTo(User, "createdById"),
      updated_by: this.belongsTo(User, "updatedById"),
      kind: this.string(""),
      phone_number: this.string(""),
      enable_sms: this.boolean(true),
      enable_voice: this.boolean(true),
      enable_conference: this.boolean(true),
      enable_marketing: this.boolean(true),
      is_verified: this.boolean(false),
      contact_ids: this.attr([]),
      contacts: this.hasManyBy(Contact, "contact_ids"),
    };
  }
  /*
  static saving(model) {
    useRepo(ContactPhoneContact).save(
      model.contact_ids.map((id) => {
        return { contactPhoneId: model.id, contactId: id };
      })
    );
  }
  static deleting(model) {
    useRepo(ContactPhoneContact)
      .where((pivot) => model.contact_ids.includes(pivot.contactId))
      .where("contactPhoneId", model.id)
      .delete();
  }
  */
  get pivotMap() {
    return {
      contacts: useRepo(ContactPhoneContact),
    };
  }
}
ContactPhone.entity = "contact_phones";

import { Model } from "pinia-orm";

import { Timeclock } from "src/models/Timeclock.js";
import { User } from "src/models/User.js";

export class File extends Model {
  static fields() {
    return {
      $hash: this.attr({}),
      id: this.uid(),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      createdById: this.attr(null),
      updatedById: this.attr(null),
      created_by: this.belongsTo(User, "createdById"),
      updated_by: this.belongsTo(User, "updatedById"),
      kind: this.string(""),
      is_archived: this.boolean(false),
      is_deleted: this.boolean(false),
      is_shared: this.boolean(false),
      data: this.attr({}),
      size: this.number(0),
      metadata: this.string(""),
      mime_type: this.string(""),
      name: this.string(""),
      bucket: this.string(""),
      storage_name: this.string(""),
      storage_preview: this.string(""),
      storage_thumbnail: this.string(""),
      timeclock_id: this.attr(null),
      timeclock: this.belongsTo(Timeclock, "timeclockId"),
    };
  }
}
File.entity = "files";

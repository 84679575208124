import { route } from "quasar/wrappers";
import {
  createRouter,
  createMemoryHistory,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import routes from "./routes";

import { api } from "src/boot/axios";

import { LocalStorage } from "quasar";

import { useAuthStore } from "src/stores/auth.js";

const routesByGroup = {
  "New Pro": "contractor",
  "New Customer": "customer",
  "Crew Leader": "crew",
  "Crew Member": "crew",
  Manager: "manager",
  User: "customer",
};

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */
export default route(function (/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : process.env.VUE_ROUTER_MODE === "history"
    ? createWebHistory
    : createWebHashHistory;

  const Router = createRouter({
    scrollBehavior: (to, _from, _savedPosition) => {
      if (to.hash) {
        return {
          el: to.hash,
          behavior: "smooth",
          top: 50,
        };
      }
    },
    routes: routes.landing,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE),
  });

  function useRoutes(routes) {
    Router.getRoutes().forEach((route) => {
      if (Router.hasRoute(route.name)) {
        Router.removeRoute(route.name);
      }
    });

    routes.forEach((route) => {
      Router.addRoute(route);
    });
  }

  const auth = useAuthStore();

  async function checkTokenLogin(to) {
    if (to.query.hasOwnProperty("login")) {
      auth.logoutUser();

      try {
        const response = await api.post("token_login", {
          data: {
            token: to.query.login,
          },
        });
        auth.loginUser(response.data.token, response.data.user);
      } catch (error) {
        console.error(error);
      }
    }
  }

  Router.beforeResolve(async (to) => {
    let isLoggedIn = LocalStorage.getItem("isLoggedIn");
    if (isLoggedIn && !auth.isLoggedIn) {
      const authToken = LocalStorage.getItem("authToken");
      const authUser = LocalStorage.getItem("authUser");

      if (
        authToken &&
        authUser &&
        authToken !== "undefined" &&
        authUser !== "undefined"
      ) {
        auth.loginUser(authToken, authUser);
      } else {
        auth.logoutUser();
        isLoggedIn = false;
      }
    }

    await checkTokenLogin(to);
    isLoggedIn = LocalStorage.getItem("isLoggedIn");

    if (!isLoggedIn) {
      if (!Router.hasRoute("landing")) {
        useRoutes(routes.landing);
        return to.fullPath;
      }
    } else {
      if (auth.user.groups.length == 1) {
        let group = auth.user.groups[0].name;

        if (!Router.hasRoute(routesByGroup[group])) {
          useRoutes(routes[routesByGroup[group]]);
          return to.fullPath;
        }
      } else if (auth.user.groups.length > 1) {
        let lastContext = LocalStorage.getItem("lastContext");
        if (lastContext) {
          if (!Router.hasRoute(routesByGroup[lastContext])) {
            useRoutes(routes[routesByGroup[lastContext]]);
            return to.fullPath;
          }
        }
      }
    }
  });

  return Router;
});

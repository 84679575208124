import { Model } from "pinia-orm";

import { User } from "src/models/User.js";

export class Tag extends Model {
  static fields() {
    return {
      $hash: this.attr({}),
      id: this.uid(),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      createdById: this.attr(null),
      updatedById: this.attr(null),
      created_by: this.belongsTo(User, "createdById"),
      updated_by: this.belongsTo(User, "updatedById"),
      kind: this.string(""),
      name: this.string(""),
    };
  }
}
Tag.entity = "tags";
